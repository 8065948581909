@import '~antd/dist/antd.less';
@import '~antd/lib/style/themes/default.less';

* {
  margin: 0;
  padding: 0;
  border: 0;
}

body, html {
  font-family: 'Lato', sans-serif;
}

@primary-color: #366075;@heading-color: #276177;@text-color: #62778f;@text-color-secondary: #becad7;@border-radius-base: 16px;